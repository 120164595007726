export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'Home',
    action: 'read',
  },
  // {
  //   title: 'Admin',
  //   route: 'admin',
  //   icon: 'FileIcon',
  //   resource: 'Admin',
  //   action: 'read',
  // },
  {
    title: 'Pendidikan',
    route: 'pendidikan',
    icon: 'AwardIcon',
    resource: 'Pendidikan',
    action: 'read',
  },
  {
    title: 'Member',
    route: 'member',
    icon: 'UsersIcon',
    resource: 'Member',
    action: 'read',
  },

  {
    title: 'Mentor Register',
    route: 'mentorship-register',
    icon: 'UserCheckIcon',
    resource: 'Mentorship',
    action: 'read',
  },
  {
    title: 'Mentorship List',
    route: 'mentorship-schedule',
    icon: 'ClockIcon',
    resource: 'Mentorship',
    action: 'read',
  },
]
